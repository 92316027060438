.drawerstyle div {
	background-color: #242363 !important;
	overflow: hidden;
	width: 60px;
}

.logoadjust {
	position: relative;
	top: 9px;
	left: 12px;
	height: 60px;
}

.f_menu_icon {
	padding-top: 8px;
	padding-bottom: 8px;
}

.top_bar {
	background-color: #f1f3f4 !important;
}
:root {
	overflow: hidden;
}
.MuiAccordionSummary-content.Mui-expanded {
	margin: 0 !important;
}

.MuiCardContent-root.overflow-auto {
	padding: 10px 7px 10px 9px !important;
}

.MuiCardContent-root.overflow-auto:empty {
	display: none;
}

button :focus{
	outline: none !important;

}

